import { updateSingleOrgGroup } from '@/api/accidentplan-api';
import { UpdateSingleOrgGroupDTO } from '@/api/dto/org';
import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import { ElMessage } from 'element-plus';
import { ref, Ref, watch } from 'vue';
import { useSingleOrgGroup } from './useSingleOrgGroup';

export function useOrgGroupForm(
  id: Ref<number>,
  formRef: Ref<any>,
) {
  const info = useSingleOrgGroup(id);
  const contactCount = ref(1)
  const form = ref<UpdateSingleOrgGroupDTO>({
    id: id.value,
    emergency_contact_instructions: '',
    contacts: [{name: '', phone:''}],
  });

  watch(info.group, group => {
    if (group) {
      if (!group.contacts.length) {
        group.contacts.push({name: '', phone: ''})
      }
      form.value = group;
    }
  });

  watch(contactCount, count => {
    for (var i = 0; i < count; i++) {
      if (form.value.contacts.length <= i) {
        form.value.contacts.push({name: '', phone: ''});
      }
    }
  })

  const rules = {
    name: [{ required: true, message: 'Name is required', trigger: 'blur' }],
    // address: [
    //   { required: true, message: 'Address is required', trigger: 'blur' },
    // ],
    // city: [{ required: true, message: 'City is required', trigger: 'blur' }],
    // state: [{ required: true, message: 'State is required', trigger: 'blur' }],
    // country: [
    //   { required: true, message: 'Country is required', trigger: 'blur' },
    // ],
    // zip: [{ required: true, message: 'Zip code is required', trigger: 'blur' }],
    // main_phone: [
    //   {
    //     required: true,
    //     message: 'Main phone number is required',
    //     trigger: 'blur',
    //   },
    // ],
    // us_dot_num: [
    //   { required: true, message: 'US DOT # is required', trigger: 'blur' },
    // ],
  };

  const saveState = ref<FetchStates>(FetchStates.UNFETCHED);

  const dispatchSubmit = async () => {
    const isValid = await new Promise<boolean>(res =>
      formRef.value.validate((_isValid: boolean) => res(_isValid)),
    );

    if (!isValid) return;

    saveState.value = FetchStates.FETCHING;
    try {
      console.log(form.value);
      await updateSingleOrgGroup(form.value);
      saveState.value = FetchStates.FETCHED;
      ElMessage.success('Successfully saved organization.');
    } catch (err) {
      console.error(err);
      alertError(err, 'There was an error updating the organization.');
      saveState.value = FetchStates.UNFETCHED;
    }
  };

  return {
    rules,
    form,
    contactCount,
    dispatchSubmit,
    saveState
  };
}
